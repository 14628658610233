import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from "prop-types"
import 'react-lazy-load-image-component/src/effects/blur.css';


const Trackitem = (props) => {
  const { titleClassName } = props;

  return (
    <div className="track__content">
      <LazyLoadImage effect="blur" src={props.image} alt={props.title} />
      <div className={`track__text ${props.variant === "white" ? "track__text_white" : ""}`}>
        <h3 className={titleClassName}>{props.title}</h3>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

Trackitem.defaultProps = {
  variant: "black",
  titleClassName: ""
};

Trackitem.propTypes = {
  variant: PropTypes.string,
  titleClassName: PropTypes.string
};


export default Trackitem;
