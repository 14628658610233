import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Slider from 'react-slick';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Carousel = (props) => {
  return (
    <Slider {...settings}>
      {props.images.map((item) => {
        return <LazyLoadImage effect="blur" key={item.id} src={item.image} alt={item.alt} title={item.alt} />;
      })}
    </Slider>
  );
};

export default Carousel;
