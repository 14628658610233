import React from 'react';

const SchoolHeader = (props) => {
  return (
    <div className="container">
      <div className="school__header">
        <h2 className="linethrough-header">{props.title}</h2>
        <p>{props.subtitle}</p>
      </div>
    </div>
  );
};
export default SchoolHeader;
