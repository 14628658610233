import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import MonthSlider from "../MonthSlider";


const SchoolCard = (props) => {
	return (
		<div className="schoolcard">
			<div className="row">
				<div className="col">
					<div className="schoolcard__image">
						<LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
					</div>
				</div>
				<div className="col">
					<div className="schoolcard__content">
						<h3>{props.header}</h3>
						<hr />
						<p>{props.text}</p>

						<div className="timeandprice">
							<p><span>{props.multipleTime ? 'Times' : 'Time'}:</span> {props.time}</p>
							<p><span>Price:</span> {props.price}</p>
							{props.note && <p className="schoolcard__note">{props.note}</p>}
						</div>
						{props.url &&
							<a href={props.url ? props.url : "https://pds.eventsbmw.com/"} target="_blank"
							   rel="noopener noreferrer">
								<button className="btn btn-black">Book Now</button>
							</a>
						}
						{props.monthSliderQuery && (
							<MonthSlider
								brand="MINI"
								monthSliderHeader={props.monthSliderHeader}
								backgroundColor="#E5E5E5"
								urlQuery={props.monthSliderQuery}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default SchoolCard;
